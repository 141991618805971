import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import { Text, Box } from "@chakra-ui/react"

export default function Home() {
  return (
    <Layout title="Impressum" slug="imprint/">
      <Box>
        <b>Für den Inhalt dieser Seiten verantwortlich:</b>
        <br />
        <br />
        Anne Rieck
        <br />
        Karl-Pokern-Straße 30
        <br />
        12587 Berlin
        <br />
        <br />
        E-Mail: hello.annerieck@gmail.com
        <br />
        Telefonnummer: +49 152 244 411 06
        <br />
        Steuernummer: 36/493/02030
        <br />
        <br />
        <Text>
          Dieses Impressum gilt auch für den, auf dieser Seite verlinkten,
          Instagram Account.
        </Text>
        <br />
        <br />
        <Text>
          <b>Haftung des Inhalts:</b>
          <br />
          Als Diensteanbieter bin ich gemäß § 7 Abs.1 TMG für eigene Inhalte auf
          diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8
          bis 10 TMG bin ich als Diensteanbieter jedoch nicht verpflichtet,
          übermittelte oder gespeicherte fremde Informationen zu überwachen oder
          nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
          hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung
          von Informationen nach den allgemeinen Gesetzen bleiben hiervon
          unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
          Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
          Bekanntwerden von entsprechenden Rechtsverletzungen werde ich diese
          Inhalte sofort entfernen.
        </Text>
        <br />
        <Text>
          <b>Links zu externen Webseiten:</b>
          <br />
          Meine Webseite enthält Links zu externen Webseiten, auf deren Inhalte
          ich keinen Einfluss habe. Deshalb kann ich für diese fremden Inhalte
          auch keine Haftung übernehmen. Für die Inhalte der verlinkten Seiten
          ist der jeweilige Anbieter oder Betreiber der Seiten selbst
          verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
          Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
          Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Dennoch,
          bei Bekanntwerden einer Rechtsverletzung werde ich diese Links sofort
          entfernen.
        </Text>
        <br />
        <Text>
          <b>Hinweis zum Urheberrecht:</b>
          <br />
          Die erstellten Inhalte auf diesen Seiten unterliegen dem Urheberrecht.
          Das Kopieren, Bearbeiten, Publizieren und jede Art der Verwertung
          außerhalb der Grenzen des Urheberrechtes sind nur mit einer
          schriftlichen Zustimmung des jeweiligen Autors zulässig. Urheberrechte
          Dritter werden auf diesen Seiten beachtet und Inhalte Dritter werden
          dementsprechend gekennzeichnet. Sollte trotzdem eine
          Urheberrechtsverletzung bekannt werden, bitte ich um einen
          entsprechenden Hinweis.
        </Text>
        <br />
        <Text>
          <b>Bildnachweis:</b>
          <br />
          <Link
            to="https://tpmi.de/"
            title="Tim Piotrowski - Motion &amp; Illustration"
          >
            Tim Piotrowski
          </Link>{" "}
          - freiberuflicher Motion Designer und Illustrator-
          <br />
        </Text>
        <br />
        <Text>
          <b>Hinweis zum Yogaunterricht:</b>
          <br />
          Jede TeilnehmerIn nimmt auf eigene Verantwortung an meinem
          Yogaunterricht teil. Mein Yogaunterricht ersetzt keine Therapie. Bei
          Beschwerden empfehle ich unbedingt einen Arzt/ PhysiotherapeutIn zu
          konsultieren, um zu erfragen welche Yogaübungen geeignet sind. Gerne
          gebe ich eine Weiterempfehlung <Link to="/contact/">(Kontakt).</Link>
        </Text>
      </Box>
      <br />
      <br />
    </Layout>
  )
}
